// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catena-offering-js": () => import("./../../../src/pages/catena-offering.js" /* webpackChunkName: "component---src-pages-catena-offering-js" */),
  "component---src-pages-form-success-js": () => import("./../../../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loyalty-form-success-js": () => import("./../../../src/pages/loyalty-form-success.js" /* webpackChunkName: "component---src-pages-loyalty-form-success-js" */),
  "component---src-pages-loyalty-naming-js": () => import("./../../../src/pages/loyalty-naming.js" /* webpackChunkName: "component---src-pages-loyalty-naming-js" */),
  "component---src-pages-march-wine-offering-js": () => import("./../../../src/pages/march-wine-offering.js" /* webpackChunkName: "component---src-pages-march-wine-offering-js" */)
}

